import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const ProSteelOzellikler = () => {
    return (
        <div className="features-area pt-80 pb-50 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Özellikler</h2>
                    <div className="bar"></div>
                    <p> Çelik konstruksiyon üretimi yapan fabrikalara özel lazer kesim, çatım, kaynatma,kumlama,boya,galvaniz,plazma,testere, sevkiyat ve montaj işlemlerini takip eder. Tekla programı ile entegrelidir </p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Tekla İle Entegreli
                                </Link>
                            </h3>
                            <p>Tekla programından aldığınız Assembly listeleriyle bağlantılı üretim takibini yapabilirsiniz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   Proje Takibi
                                </Link>
                            </h3>
                            <p>Birden fazla projeyi aynı anda takip edebilir. Son durumları hakkında özet bilgi alabilirsiniz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Grid />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                   Kaynak - Çatım - Boya
                                </Link>
                            </h3>
                            <p>Aynı anda ilerleyen üretim esnasında karışıklıkları önler, Süre planlamanızda yardımcı olur</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Info />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                 Plazma - Testere
                                </Link>
                            </h3>
                            <p>Plazma ve Testere istasyonlarını planlayın takip edin. Kullandığınız plaka miktarlarını fireleri analiz edin </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Box />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Sevkiyat
                                </Link>
                            </h3>
                            <p>Yurt içi ve yurt dışı sevkiyatlarınızı planlayın, Check Listler elde edin. İhracata uyumlu listeleri tek tıkla hazırlayın </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Bell />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Montaj
                                </Link>
                            </h3>
                            <p>Montaj işlemlerini ister taşero edin isterseniz kendiniz yapın.Sevkiyatı kabul edilen ve montajı yapılan hizmet kadar hakediş yapabilirsiniz</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Planlama
                                </Link>
                            </h3>
                            <p>Yeni projelerinizi planlarken ihtiyacınız olan istasyonların son durumlarını tek tıkla görebilir, planlarınızı mevcut duruma göre yapabilirsiniz </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   Performans Analizleri & Raporlar
                                </Link>
                            </h3>
                            <p>İstasyon bazlı veya aylık bazda çalışma performansı analizi yapabilirsiniz. Üretim adetlerini tonaj bilgilerini görüntüleyebilirsiniz. Ayrıca tüm raporlara hızlıca ulaşabilirsiniz</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProSteelOzellikler;  